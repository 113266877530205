.page {
    padding-top: 30px;
    padding-bottom: 30px;

    &-content {
        width: calc(753 / 1024 * 100%);
        line-height: 24px;

        @media (--until-tablet) {
            width: 100%;
        }

        a {
            color: $red;
            transition: .5s color ease;

            &:hover,
            &:focus {
                color: #000;
            }
        }

        ul,
        ol {
            margin: 0 0 20px;
            padding: 0 0 0 20px;

            li {
                margin-bottom: 15px;
            }
        }

        ul {
            list-style: square;
        }

        ol {
            list-style: decimal;
        }

        h2 {
            margin-top: 30px;
            margin-bottom: 15px;
        }

        h3 {
            margin-top: 20px;
            margin-bottom: 5px;
        }

        strong,
        p.question {
            font-weight: bold;
        }
    }

    &-intro {
        font-size: 24px;
        line-height: 35px;
        font-family: $title;
        color: #000;
    }
}

.system-block {
    padding-top: 30px;
    padding-bottom: 30px;

    form {
        width: 440px;
    }

    .form-row {
        margin-bottom: 20px;
        @mixin cf;
    }

    input,
    textarea {
        width: 100%;
    }

    input {
        height: 42px;
        line-height: 42px;
        padding: 0 10px;
    }

    textarea {
        height: 100px;
        line-height: 20px;
        padding: 10px;
    }

    .button.form-submit {
        float: left;
        margin-top: 20px;
    }
}

.more-detail {
    position: relative;

    &::before {
        content: '';
        display: block;
        left: 0;
        width: 100%;
        height: 10px;
        background-image: url(../img/grad-top.png);
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: 100% 100%;
        position: absolute;
        z-index: 1;
        opacity: .8;

        @media (--until-tablet) {
            opacity: .6;
            height: 5px;
            width: calc(100% - 15px);
            left: 15px;
        }
    }

    &--last {
        padding-top: 10px;

        &::before {
            top: 0;
        }
    }

    &--first {
        margin-bottom: 40px;
        padding-bottom: 40px;

        &::before {
            top: 100%;
        }
    }
}

.rating-level {
    @mixin cf;
    display: flex;
    margin-bottom: 30px;

    &-item {
        flex: 0 0 calc(496 / 1024 * 100%);
        background-color: $grey-light;
        padding: 20px 30px;

        &:nth-child(odd) {
            margin-right: calc(30 / 1024 * 100%);
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    .rating-level-title {
        margin-top: 0;
        margin-bottom: 15px;
    }

    @media (--until-tablet) {
        display: block;
        margin-bottom: 15px;

        .rating-level-title {
            font-size: 18px;
        }

        &-item {
            width: 100%;
            margin-bottom: 20px;
            padding: 15px;

            &:nth-child(odd) {
                margin-right: 0;
            }
        }
    }
}
