select::-ms-expand {
    display: none;
    background: transparent;
    border: 0;
}

.filters {
    background-color: $grey-light;
    min-height: 70px;
    padding: 15px 0;

    @media (--until-tablet) {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 50px;

        form {
            padding: 0 0 15px;
        }

        .contain {
            overflow: hidden;
            transition: .5s height ease;
        }
    }

    @media (--tablet) {
        padding-bottom: 0;
    }

    @media (--mobile-large) {
        padding-bottom: 0;
    }

    &-toggle {
        border: 0;
        background: $grey-light;
        color: $black;
        height: 50px;
        line-height: 50px;
        text-align: left;
        display: none;
        overflow-x: hidden;
        position: relative;
        font-family: $title;
        font-size: 16px;
        padding: 0 15px;
        width: 100%;
        cursor: pointer;
        transition: .5s color ease;

        &::after {
            content: '';
            display: block;
            width: 12px;
            height: 100%;
            background-image: url(../img/chevron-down.png);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            transition: .5s transform ease;
            position: absolute;
            top: 0;
            right: 15px;
        }

        &:hover {
            color: $red;
        }

        &:focus {
            outline: 0;
        }

        &.is_active {
            color: $red;

            &::after {
                transform: rotate(180deg);
            }
        }

        @media (--until-tablet) {
            display: block;
        }
    }
}

.filter {
    position: relative;
    float: left;
    width: calc(321 / 1024 * 100%);
    overflow: hidden;

    &:not(:nth-child(3n+3)) {
        margin-right: calc(30 / 1024 * 100%);
    }

    &--alt {
        min-height: 40px;
        z-index: 9;
        background-color: #fff;
        overflow: visible;
    }

    @media (--mobile-large) {
        width: 100%;

        &:not(:nth-child(3n+3)) {
            margin-right: 0;
        }

        &--alt {
            width: 49%;
            margin-top: 15px;

            &:nth-of-type(2) {
                margin-right: 2%;
            }
        }
    }

    @media (--mobile) {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        &:not(:nth-child(3n+3)) {
            margin-right: 0;
        }
    }

    &-contain {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 40px;
        overflow: hidden;
        transition: .5s height ease, 1.5s box-shadow ease;

        &.is_active {
            box-shadow: 0 0 9px 0 rgba(0,0,0,.25);
        }
    }

    &-input,
    &-label,
    &-submit,
    &-button {
        display: block;
        height: 40px;
        border: 0;
        font-size: 16px;
        color: #000;
    }

    &-label,
    &-input,
    &-button {
        padding: 0 15px;
    }

    &-label,
    &-submit {
        position: absolute;
        top: 0;
        z-index: 2;
    }

    &-button,
    &-submit {
        cursor: pointer;
        background-color: transparent;
        width: 100%;
        text-align: left;
    }

    &-input {
        line-height: 38px;
        width: 100%;
        position: relative;

        &::placeholder {
            transition: .5s opacity ease;
        }

        &:focus {
            outline: 0;

            &::placeholder {
                opacity: 0;
            }
        }
    }

    &-label {
        line-height: 40px;
    }

    &-submit {
        right: 0;
        background-image: url(../img/search.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 16px auto;
        width: 40px;
        text-indent: 115%;
        overflow: hidden;
        white-space: nowrap;
        padding: 0;
        transition: .5s background ease;

        &:focus,
        &:hover {
            background-image: url(../img/search-black.png);
        }
    }

    &-button {
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 40px;
        transition: .5s background ease, .5s color ease;

        &::after {
            content: '';
            display: block;
            background-image: url(../img/chevron-down.png);
            background-repeat: no-repeat;
            background-size: 10px auto;
            background-position: 50% 50%;
            width: 40px;
            height: 100%;
            transition: .5s transform ease, .5s background ease;
            backface-visibility: hidden;
            transform-origin: 50% 50%;
            position: absolute;
            top: 0;
            right: 0;
        }

        &.is_pressed {

            &::after {
                transform: rotate(180deg);
            }
        }

        &:hover,
        &:focus {
            background-color: $red;
            color: #fff;

            &::after {
                background-image: url(../img/chevron-down-white.png);
            }
        }
    }

    &-list {
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: #fff;
        position: absolute;
        top: 40px;
        transition: .5s height ease;
        width: 100%;
        z-index: 9;
        overflow: hidden;
    }

    &-item {
        border-bottom: 1px solid rgba(0,0,0,.1);
    }

    &-link {
        padding: 15px;
        color: $red;
        background-color: #fff;
        font-size: 16px;
        display: block;
        text-decoration: none;
        transition: .5s color ease, .5s background-color ease;

        &:hover,
        &:focus {
            background-color: #000;
            color: #fff;
        }
    }
}

.filters .form-row {
    position: relative;
    float: left;
    width: calc(320 / 1100 * 100%);
    margin-right: calc(20 / 1100 * 100%);
    overflow: hidden;

    &:nth-child(4n+4) {
        margin-right: 0;
    }

    &:last-child {
        width: auto;
    }

    &--alt {
        min-height: 40px;
        z-index: 9;
        background-color: #fff;
        overflow: visible;
    }

    @media (--tablet) {
        width: calc(374 / 768 * 100%);
        margin-bottom: 15px;
        margin-right: calc(20 / 768 * 100%);

        &:nth-child(4n+4) {
            margin-right: calc(20 / 768 * 100%);
        }

        &:nth-child(even) {
            margin-right: 0;
        }
    }

    @media (--mobile-large) {
        width: calc(230 / 480 * 100%);
        margin-bottom: 15px;
        margin-right: calc(20 / 480 * 100%);

        &:nth-child(4n+4) {
            margin-right: calc(20 / 480 * 100%);
        }

        &:nth-child(even) {
            margin-right: 0;
        }

        &--alt {
            width: 49%;
            margin-top: 15px;

            &:nth-of-type(2) {
                margin-right: 2%;
            }
        }
    }

    @media (--mobile) {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        &:not(:nth-child(4n+4)) {
            margin-right: 0;
        }
    }
}

.form-row .form-autocomplete.form-text,
.form-row > label,
.button.form-submit {
    display: block;
    height: 40px;
    border: 0;
    font-size: 16px;
    color: #000;
}

.form-row > label,
.form-row .form-autocomplete.form-text {
    padding: 0 15px;
}

.button.form-submit {
    cursor: pointer;
    text-align: left;
    background-color: $red;
    color: #fff;
    float: right;
    padding: 0 15px;
    transition: .5s background ease;
    margin-top: 40px;

    &:focus,
    &:hover {
        background-color: #000;
    }

    @media (--tablet) {
        float: left;
    }

    @media (--mobile-large) {
        float: left;
        margin-bottom: 15px;
    }

    @media (--mobile) {
        float: left;
        margin-top: 0;
    }
}

input.form-autocomplete.form-text {
    line-height: 38px;
    width: 100%;
    position: relative;
    background-position: 97% 50%;

    &::placeholder {
        transition: .5s opacity ease;
    }

    &:focus {
        outline: 0;

        &::placeholder {
            opacity: 0;
        }
    }
}

.form-row > label {
    line-height: 40px;
    padding: 0;
    display: block;
    font-family: $title;
}

.select-contain {
    background-color: #fff;
    width: 100%;
    height: 40px;
    transition: .5s background ease, .5s color ease;
    position: relative;

    &::after {
        content: '';
        display: block;
        background-image: url(../img/chevron-down.png);
        background-repeat: no-repeat;
        background-size: 10px auto;
        background-position: 50% 50%;
        width: 40px;
        height: 100%;
        transition: .5s transform ease, .5s background ease;
        backface-visibility: hidden;
        transform-origin: 50% 50%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        pointer-events: none;
    }

    > select {
        background-color: #fff;
        width: 100%;
        height: 40px;
        transition: .5s background ease, .5s color ease;
        appearance: none;
        border: 0;
        border-radius: 0;
        padding: 0 15px;
        text-indent: .01px; /* Removes default arrow from firefox */
        text-overflow: ''; /* Removes default arrow from firefox */
        background-image: none;

        &::ms-expand {
            display: none;
            opacity: 0;
            visibility: hidden;
            background: transparent;
            border: 0;
        }
    }

    select::ms-expand {
        display: none;
        opacity: 0;
        visibility: hidden;
        background: transparent;
        border: 0;
    }
}
