.production {

    &-info {
        background-color: #000;
        color: #fff;
        height: 50px;
        line-height: 50px;
        font-size: 18px;

        @media (--until-tablet) {
            font-size: 14px;
            height: auto;
            line-height: 42px;
        }
    }

    &-director,
    &-year,
    &-duration,
    &-status {
        display: block;
        font-family: $title;
    }

    &-director,
    &-year,
    &-duration {
        float: left;
    }

    &-director,
    &-year {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid rgba(255,255,255,.2);
    }

    &-director {

        @media (--until-tablet) {
            border-right: 0;
            padding: 0 15px;
            float: none;
            clear: both;
            margin: 0 -15px;
            border-bottom: 1px solid rgba(255,255,255,.2);
        }
    }

    &-amazon,
    &-itunes {
        text-indent: 115%;
        white-space: nowrap;
        overflow: hidden;
        height: 25px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        display: inline-block;
        vertical-align: middle;
    }

    &-amazon {
        background-image: url(../img/amazon.png);
        width: 52px;
    }

    &-itunes {
        background-image: url(../img/itunes.png);
        width: 69px;
        margin-right: 14px;
    }

    &-status {
        float: right;
        padding: 0 20px;
        height: 50px;

        &.is_on-tv,
        &.is_in-production,
        &.is_in-cinemas,
        &.is_on-internet {
            background-color: $red;
            color: #fff;
        }

        &.is_watch-on-all4 {
            background-color: #fff;
            color: #000;

            a {
                color: #000;
                transition: .5s color ease;
            }

            a:hover,
            a:focus {
                color: $red;
            }

            .on-all4 {
                text-indent: 115%;
                white-space: nowrap;
                overflow: hidden;
                width: 38px;
                height: 25px;
                background-image: url(../img/logo-all4.png);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: contain;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
            }
        }

        @media (--until-tablet) {
            line-height: 42px;
            height: auto;
            float: none;
            clear: both;
            margin-left: -15px;
            margin-right: -15px;
            padding: 0 15px;
            display: block;
        }
    }

    &-watch {
        color: #fff;
        text-decoration: underline;
        display: inline-block;
        margin-left: 10px;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &-videos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media (--until-tablet) {
            display: block;
        }

        &:last-child {
            margin-bottom: 50px;
        }
    }

    &-video {
        flex: auto;
        width: calc(449 / 1440 * 100%);
        margin-right: 10px;
        margin-bottom: 10px;
        background-color: #000;
        position: relative;

        &-contain {
            width: 100%;
            height: 0;
            position: relative;
            padding-top: 56.25%;
            overflow: hidden;
        }

        .video-js {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border: 0;
            background-color: #fff;
        }

        @media (--until-tablet) {
            margin-bottom: 10px;
            width: 100%;
        }
    }

    &-play {
        width: 36px;
        height: 36px;
        background-size: 12px auto;
        bottom: 0;
    }

    &-gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 0;
        list-style: none;

        &-item {
            flex: auto;
            width: calc(449 / 1440 * 100%);
            margin-right: 10px;
            margin-bottom: 10px;

            @media (--mobile-large) {
                width: 45%;
            }

            @media (--mobile) {
                width: 100%;
                margin-right: 0;
            }
        }

        img {
            vertical-align: bottom;
            width: 100%;
            max-height: 100%;
        }
    }

    &-content {
        padding-top: 50px;
        padding-bottom: 50px;

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $title;
        }

        @media (--until-tablet) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    &-poster {
        float: left;
        width: calc(200 / 1024 * 100%);
        margin: 0 calc(40 / 1024 * 100%) 0 0;
        box-shadow: 0 1px 7px rgba(0,0,0,.3);

        img {
            vertical-align: bottom;
            width: 100%;
        }

        @media (--mobile) {
            display: none;
        }
    }

    &-detail {

        .has_poster & {
            width: calc(782 / 1024 * 100%);
            float: right;
            clear: right;

            @media (--mobile) {
                width: 100%;
                float: none;
                clear: both;
            }
        }
    }

    &-intro {
        font-size: 24px;
        line-height: 35px;
        font-family: $title;
        margin: 0 0 25px;

        @media (--until-desktop) {
            float: none;
            clear: both;
            width: 100%;
        }

        @media (--mobile) {
            font-size: 20px;
            line-height: 28px;
        }
    }

    &-desc {
        font-size: 15px;
        line-height: 25px;
        font-family: $sans;
        color: #333;
        float: left;
        width: calc(505 / 840 * 100%);

        :last-child {
            margin-bottom: 0;
        }

        @media (--tablet) {
            width: 60%;
        }

        @media (--until-tablet) {
            float: none;
            clear: both;
            width: 100%;
            padding-bottom: 25px;
        }
    }

    &-aside {
        width: calc(249 / 840 * 100%);
    }

    &-crew {
        margin: 0 0 15px;
        padding: 0;
        list-style: none;
        font-size: 14px;
        line-height: 21px;

        @media (--until-tablet) {
            margin-bottom: 0;
        }
    }

    &-role {
        margin-bottom: 15px;

        @media (--until-tablet) {

            &:last-child {
                margin-bottom: 0;
            }
        }

        h3 {
            margin-top: 0;
            margin-bottom: 5px;
            font-size: 14px;
            line-height: 21px;
            font-weight: normal;
            font-family: $title;
        }

        p {
            margin: 0;
        }
    }

    &-downloads {
        border-top: 1px solid rgba(0,0,0,.1);
        padding-top: 15px;
        font-size: 14px;
        line-height: 26px;
        font-family: $title;
    }

    &-file {
        display: block;
        color: $red;
        text-decoration: none;
        transition: .5s color ease, .5s background ease;
        padding-right: 24px;
        background-image: url(../img/arrow-right-red.png);
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: 12px auto;

        &:hover,
        &:focus {
            color: #000;
            background-image: url(../img/arrow-right-black.png);
        }
    }
}
