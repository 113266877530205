ul.share-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;

    @media (--tablet) {
        justify-content: center;
    }

    @media (--until-tablet) {
        justify-content: center;
    }
}

.share {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url(../img/grad-top.png);
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: 80% auto;
    display: flex;
    justify-content: space-between;

    @media (--tablet) {
        display: block;
    }

    @media (--until-tablet) {
        display: block;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &-title {
        font-size: 18px;
        line-height: 24px;
        color: #000;
        font-family: $title;

        @media (--tablet) {
            margin-bottom: 15px;
            display: block;
            clear: both;
            text-align: center;
        }

        @media (--until-tablet) {
            margin-bottom: 15px;
            display: block;
            clear: both;
            text-align: center;
        }
    }

    &-item {

        &:not(:first-child) {
            padding-left: 30px;
        }

        @media (--until-tablet) {

            &:not(:first-child) {
                padding-left: 20px;
            }
        }
    }

    &-link {
        padding: 0 30px 0 0;
        color: $red;
        display: block;
        position: relative;
        transition: .5s color ease;
        text-decoration: none;
        font-size: 14px;
        line-height: 24px;
        background-color: transparent;
        border: 0;
        margin: 0;
        cursor: pointer;

        &::after {
            display: block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 24px;
            font-family: $icons;
            color: $red;
            transition: .5s color ease;
            position: absolute;
            right: 0;
            top: 0;
        }

        &--email::after {
            content: '\f199';
        }

        &--facebook::after {
            content: '\f308';
        }

        &--twitter::after {
            content: '\f304';
        }

        &--linkedin::after {
            content: '\f30c';
        }

        &:hover,
        &:focus {
            color: #000;

            &::after {
                color: #000;
            }
        }

        @media (--until-tablet) {
            text-indent: 115%;
            white-space: nowrap;
            overflow: hidden;
            width: 24px;
            padding-right: 0;

            &::after {
                text-indent: 0;
            }
        }
    }
}
