/* Breakpoints */

@custom-media --mobile (width <= 479px);
@custom-media --mobile-large (480px <= width < 768px);
@custom-media --tablet (768px <= width < 1024px);
@custom-media --desktop (1024px <= width < 1140px);
@custom-media --desktop-large (1140px <= width < 1440px);
@custom-media --desktop-wide (1440px <= width < 1920px);
@custom-media --desktop-huge (width >= 1920px);
@custom-media --until-tablet (width < 768px);
@custom-media --until-desktop (width < 1024px);
@custom-media --until-desktop-large (width < 1140px);
@custom-media --until-desktop-wide (width < 1440px);
@custom-media --until-desktop-huge (width < 1920px);
@custom-media --from-tablet (width >= 768px);
@custom-media --from-desktop (width >= 1024px);
@custom-media --from-desktop-large (width >= 1140px);
@custom-media --from-desktop-wide (width >= 1440px);
@custom-media --from-desktop-huge (width >= 1920px);

/* colours */

$black: #121212;
$a11y: #00ff14;
$red: #d2081e;
$red-dark: #a50f01;
$black: #333;
$grey: #555;
$grey-light: #f0f0f0;
$grey-lightish: #eaeaea;

/* font styles */

$fwn: normal;
$fwb: bold;

/* fonts */

@font-face {
    font-family: icons;
    src: url(../fonts/icons.eot?85380945);
    src:
        url(../fonts/icons.eot?85380945#iefix) format('embedded-opentype'),
        url(../fonts/icons.woff2?85380945) format('woff2'),
        url(../fonts/icons.woff?85380945) format('woff'),
        url(../fonts/icons.ttf?85380945) format('truetype'),
        url(../fonts/icons.svg?85380945#icons) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Film4';
    src:
        url(../fonts/film4-bold-webfont.woff2) format('woff2'),
        url(../fonts/film4-bold-webfont.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Film4';
    src:
        url(../fonts/film4-medium-webfont.woff2) format('woff2'),
        url(../fonts/film4-medium-webfont.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Film4';
    src:
        url(../fonts/film4-regular-webfont.woff2) format('woff2'),
        url(../fonts/film4-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

$icons: 'icons';
$title: 'Film4', 'Helvetica Neue', helvetica, arial, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', verdana, sans-serif, -apple-system, 'BlinkMacSystemFont';
$sans: 'Trebuchet MS', trebuchet, 'TrebuchetMS', 'Helvetica Neue', helvetica, arial, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', verdana, sans-serif, -apple-system, 'BlinkMacSystemFont';
$serif: georgia, 'Times New Roman', times, serif;
$monospace: menlo, monaco, consolas, 'Liberation Mono', 'Courier New', monospace;

/* ----------------------------------------------------
 *      icon codes
 *
 *      retweet \e800
 *      heart \e801
 *      link \e802
 *      twitter \f099
 *      facebook \f09a
 *      pinterest-circled \f0d2
 *      youtube-squared \f166
 *      instagram \f16d
 *      mail-squared \f199
 *      twitter-squared \f304
 *      facebook-squared \f308
 *      linkedin-squared \f30c
 *---------------------------------------------------- */
