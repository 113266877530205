.slate {
    position: relative;
    padding-top: 40px;
    padding-bottom: 50px;
    background-color: #fff;

    @media (--tablet) {
        padding-top: 20px;
        padding-bottom: 30px;
    }

    @media (--until-tablet) {
        padding-top: 15px;
        padding-bottom: 25px;

        &::before {
            content: '';
            display: block;
            top: 0;
            left: 15px;
            width: calc(100% - 30px);
            height: 5px;
            background-image: url(../img/grad-top.png);
            background-repeat: no-repeat;
            background-position: 50% 0;
            background-size: 100% 100%;
            position: absolute;
            z-index: 4;
            opacity: .6;
        }

        .contain {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &-title {
        font-size: 42px;
        line-height: 54px;
        margin-top: 0;
        margin-bottom: 25px;
        font-family: $title;

        @media (--tablet) {
            font-size: 32px;
            line-height: 44px;
        }

        @media (--until-tablet) {
            font-size: 28px;
            line-height: 40px;
            margin-bottom: 15px;
        }
    }

    &-list {
        list-style: none;
        padding: 0;
        margin: 0;
        @mixin cf;
        overflow: hidden;
        transition: .5s height ease;
    }

    &-item {
        width: calc(331 / 1024 * 100%);
        float: left;
        padding-bottom: 35px;

        &:nth-child(3n + 1),
        &:nth-child(3n + 2) {
            margin-right: calc(15 / 1024 * 100%);
        }

        @media (--until-tablet) {
            width: 48%;

            &:nth-child(3n + 1),
            &:nth-child(3n + 2) {
                margin-right: 0;
            }

            &:nth-child(2n + 1) {
                margin-right: 4%;
            }
        }
    }

    &-image {

        img {
            max-width: 100%;
            vertical-align: bottom;
        }
    }

    &-subtitle {
        color: $red-dark;
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 0;
        margin-top: 15px;
        transition: .5s color ease;
        font-family: $title;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @media (--tablet) {
            font-size: 16px;
        }

        @media (--until-tablet) {
            font-size: 14px;
        }
    }

    &-link {
        display: block;
        text-decoration: none;

        &:hover,
        &:focus {

            .slate-subtitle {
                color: #000;
            }
        }
    }

    &-toggle {
        display: block;
        width: 100%;
        height: 42px;
        line-height: 42px;
        text-align: center;
        color: #fff;
        background-color: $red;
        padding: 0 20px;
        border: 0;
        margin: 0;
        cursor: pointer;
        transition: .5s background-color ease;
        font-family: $title;
        position: relative;
        text-indent: 115%;
        white-space: nowrap;
        overflow: hidden;

        &::after {
            background-image: url(../img/arrow-down-white.png);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: auto 20px;
            content: '';
            width: 20px;
            height: 100%;
            display: block;
            transform-origin: 50% 50%;
            transition: .5s transform ease;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -10px;
        }

        &:hover,
        &:focus {
            background-color: #000;
            outline: 0;
        }

        &.is_pressed::after {
            transform: rotate(180deg);
        }
    }

    &-direction {
        color: $red-dark;
        padding-right: 32px;
        background-image: url(../img/arrow-right-red.png);
        background-repeat: no-repeat;
        background-size: 20px auto;
        background-position: 100% 50%;
        transition: .5s color ease, .5s background ease;
        position: absolute;
        font-size: 18px;
        line-height: 24px;
        top: 15px;
        right: 170px;
        text-decoration: none;
        font-family: $title;

        &:hover,
        &:focus {
            color: #000;
            background-image: url(../img/arrow-right-black.png);
        }

        @media (--desktop-large) {
            right: 70px;
        }

        @media (--desktop) {
            right: 35px;
        }

        @media (--tablet) {
            right: 25px;
            line-height: 16px;
        }

        @media (--until-tablet) {
            right: 15px;
            line-height: 14px;
            font-size: 14px;
            padding-right: 24px;
        }
    }
}
