.contain {
    max-width: 1440px;
    margin: 0 auto;
    @mixin cf;
    position: relative;

    &--slim {
        max-width: 1100px;
        margin: 0 auto;
        @mixin cf;
        position: relative;
    }

    &--large {
        max-width: 1440px;
        margin: 0 auto;
        @mixin cf;
        position: relative;
        padding-left: 0;
        padding-right: 0;
    }

    @media (--from-desktop-wide) {
        padding-left: 170px;
        padding-right: 170px;
    }

    @media (--desktop-large) {
        padding-left: 70px;
        padding-right: 70px;
    }

    @media (--desktop) {
        padding-left: 35px;
        padding-right: 35px;

        &--slim {
            max-width: 1024px;
        }
    }

    @media (--tablet) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (--until-tablet) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

main {
    position: relative;
    background-color: #fff;
    padding-bottom: 50px;

    @media (--until-tablet) {
        padding-bottom: 20px;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,.8);
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        transition: 2s opacity ease, 2s visibility ease, 2s z-index ease;
    }

    &.is_overlaid {

        &::after {
            opacity: 1;
            visibility: visible;
            z-index: 4;

            @media (--until-tablet) {
                z-index: 7;
            }
        }
    }
}

article {
    @mixin cf;
}
