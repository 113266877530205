.search {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    z-index: -1;
    opacity: 0;
    background-color: #fff;
    transition: .5s opacity ease, .5s z-index ease, .5s height ease;

    &.is_ready {
        z-index: 10;
        opacity: 1;
    }

    &::before {
        content: '';
        display: block;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        background-image: url(../img/grad-top.png);
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: 100% 100%;
        position: absolute;
        z-index: 4;
        opacity: 0;
        transition: .5s opacity ease, .5s z-index ease;
    }

    @media (--until-tablet) {

        &.is_ready {
            z-index: 8;
        }

        &::before {
            opacity: .6;
            height: 5px;
            width: calc(100% - 15px);
            left: 15px;
            display: none;
        }
    }

    &.is_open {

        &::before {
            opacity: .8;
        }
    }

    &-contain {
        overflow: hidden;
        background-color: #fff;
        transition: .5s height ease;

        @media (--until-tablet) {
            background-color: $grey-light;
        }
    }

    &-toggle {
        border: 0;
        background: transparent;
        padding: 0;
        display: block;
        width: 76px;
        height: 66px;
        position: relative;
        text-align: left;
        cursor: pointer;
        transition: .5s color ease, .5s background ease;
        font-size: 16px;
        font-family: $title;
        text-indent: 115%;
        overflow: hidden;
        white-space: nowrap;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 16px auto;
            background-position: 50% 50%;
            transition: .5s opacity ease, .5s z-index ease, .5s color ease;
            background-image: url(../img/search.png);
        }

        @media (--until-desktop) {
            width: 66px;

            &::before {
                padding-left: 20px;
                padding-right: 20px;
                right: 0;
            }
        }

        &:hover,
        &:focus {
            color: $red;
            background-color: #fff;

            &::before {
                background-image: url(../img/search-black.png);
            }
        }

        &.is_pressed {
            background-color: #fff;

            &::before {
                background-image: url(../img/cross-red.png);
            }
        }

        &.is_pressed:hover,
        &.is_pressed:focus {
            color: $red;

            &::before {
                background-image: url(../img/cross-red.png);
            }
        }

        @media (--tablet) {

            &::before {
                right: 20px;
            }

            &::after {
                left: 20px;
                width: calc(100% - 40px);
            }
        }

        @media (--until-tablet) {
            display: none;
        }
    }

    &--status {

        &--open {
            opacity: 1;
            z-index: 1;

            .is_pressed & {
                opacity: 0;
                z-index: -1;
            }
        }

        &--close {
            opacity: 0;
            z-index: -1;

            .is_pressed & {
                opacity: 1;
                z-index: 1;
            }

            .search--toggle:hover &,
            .search--toggle:focus & {
                color: $red;
            }
        }
    }

    &-fields {
        border: 0;
        padding: 0;
        margin: 0;
        background-color: #fff;
        position: relative;

        &::before {

            @media (--until-tablet) {
                content: '';
                display: block;
                top: 100%;
                background-image: url(../img/grad-top.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: 100% 100%;
                position: absolute;
                z-index: 4;
                opacity: .6;
                height: 5px;
                width: calc(100% - 15px);
                left: 15px;
            }
        }

        @media (--until-tablet) {
            display: none;
        }

        &--mobile {
            display: none;
            background-color: transparent;

            &::before {
                display: none;
            }

            @media (--until-tablet) {
                display: block;
            }
        }
    }

    &-fields,
    &-list {
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
            z-index: -1;
        }

        &::before {
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,.8);
            top: 0;
            left: 0;
        }

        &.is_empty {
            overflow: hidden;
            opacity: 0;
        }

        &.is_loading {

            &::before {
                z-index: 11;
            }

            &::after {
                opacity: 1;
                visibility: visible;
            }
        }

        @media (--until-tablet) {

            &::before {
                background-color: $grey-light;
            }
        }
    }

    &-loading {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        opacity: 0;
        z-index: -1;
        position: absolute;
        left: 50%;
        top: 30px;
        text-indent: 115%;
        overflow: hidden;
        white-space: nowrap;
        border-top: 10px solid rgba($black, .6);
        border-right: 10px solid rgba($black, .6);
        border-bottom: 10px solid rgba($black, .6);
        border-left: 10px solid $red;
        transform: translateZ(0);
        margin-left: -50px;
        animation: spinner 1.1s infinite linear;

        &.is_loading {
            z-index: 12;
            opacity: 1;
        }
    }

    &-input {
        border-color: $red;
        border-radius: 0;
        box-shadow: none;
        border-width: 0 0 3px;
        width: 100%;
        font-size: 42px;
        height: 120px;
        line-height: 120px;
        padding-left: 66px;
        padding-top: 5px;
        color: #000;
        background-image: url(../img/search.png);
        background-repeat: no-repeat;
        background-size: 46px auto;
        background-position: 0 50%;
        transition: .5s border-color ease, .5s background ease;
        margin-bottom: 20px;

        &:hover,
        &:focus {
            outline: 0;
            border-color: #000;
            background-image: url(../img/search-black.png);
        }

        &--mobile {
            background-color: #fff;
            height: 53px;
            line-height: 53px;
            border-color: #fff;
            padding-left: 36px;
            padding-right: 36px;
            background-size: 18px auto;
            background-position: 10px 50%;
            vertical-align: bottom;
            transition: .5s border-color ease;
            font-size: 18px;
            margin-left: 15px;
            margin-right: 15px;
            width: calc(100% - 30px);
            position: relative;

            &:hover,
            &:focus {
                border-color: $red;
                outline: 0;
            }
        }
    }

    &-list {
        list-style: none;
        padding: 0;
        margin: 0;
        transition: .25s height ease, .25s opacity ease;
        opacity: 1;

        @media (--until-tablet) {
            overflow: hidden;
        }
    }

    &-item {
        position: relative;
        margin-bottom: 40px;

        &::after {
            content: '';
            display: block;
            top: 100%;
            left: 0;
            width: 100%;
            height: 10px;
            background-image: url(../img/grad-top.png);
            background-repeat: no-repeat;
            background-position: 50% 0;
            background-size: 100% 100%;
            position: absolute;
            z-index: 5;

            @media (--until-tablet) {
                opacity: .6;
                height: 5px;
                width: calc(100% - 15px);
                left: 15px;
            }
        }

        &:last-of-type {

            .search-link {
                padding-bottom: 0;
            }

            &::after {
                display: none;
            }
        }

        &--empty {
            padding-top: 24px;
        }

        @media (--until-tablet) {

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &-link {
        text-decoration: none;
        font-size: 32px;
        line-height: 42px;
        @mixin cf;
        display: block;
        color: #000;
        padding-bottom: 45px;
        padding-top: 0;

        &--article {
            min-height: 150px;
        }

        &--with-image {
            min-height: 200px;
        }

        @media (--until-tablet) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    &-title {
        margin-top: 0;
        color: $red-dark;
        float: left;
        font-family: $title;
        transition: .5s color ease;

        .search-link:hover &,
        .search-link:focus & {
            color: #000;
        }

        @media (--until-tablet) {
            font-size: 24px;
            line-height: 34px;
        }

        @media (--mobile) {
            font-size: 20px;
            line-height: 28px;
        }
    }

    &-tag {
        text-transform: capitalize;
        display: block;
        float: right;
        line-height: 32px;
        height: 32px;
        background-color: $red;
        color: #fff;
        font-size: 20px;
        padding: 0 6px;
        transition: .5s background-color ease;

        .search-link:hover &,
        .search-link:focus & {
            background-color: #000;
        }

        @media (--until-tablet) {
            font-size: 14px;
            line-height: 33px;
            clear: both;
            float: left;
            margin-bottom: 8px;
        }
    }

    &-image {
        float: left;
        clear: left;
        width: calc(180 / 1100 * 100%);
        position: relative;
        height: 0;
        overflow: hidden;
        padding-top: calc(108 / 1100 * 100%);

        img {
            width: 100%;
            vertical-align: bottom;
            top: 0;
            left: 0;
            position: absolute;
        }

        @media (--until-tablet) {
            display: none;
        }
    }

    &-info {
        font-size: 16px;
        line-height: 24px;
        clear: both;

        .search-link--image & {
            float: right;
            clear: right;
            width: calc(824 / 1024 * 100%);

            @media (--until-tablet) {
                float: none;
                width: 100%;
                clear: both;
            }
        }
    }

    &-meta {
        margin-bottom: 5px;
        display: block;
    }

    &-summary {
        margin-bottom: 0;
    }

    &-direction {
        background-color: $red;
        color: #fff;
        width: 100%;
        display: block;
        clear: both;
        text-align: center;
        border: 0;
        line-height: 42px;
        padding: 0 20px;
        text-decoration: none;
        transition: .5s background-color ease, .5s opacity ease, .5s z-index ease, .5s height ease, .5s margin ease;
        height: 0;
        opacity: 0;
        z-index: -1;
        margin: 0;

        &.is_visible {
            z-index: 1;
            opacity: 1;
            height: 42px;
            margin: 0 0 6px;
        }

        &:hover,
        &:focus {
            outline: 0;
            background-color: #000;
        }

        @media (--until-tablet) {
            margin-top: 0;
            margin-bottom: 0;

            &.is_visible {
                margin-bottom: 15px;
                margin-top: 20px;
            }
        }
    }

    &-clear {
        border: 0;
        background-color: transparent;
        padding: 0;
        margin: 0;
        position: absolute;
        width: 25px;
        height: 53px;
        text-indent: 115%;
        white-space: nowrap;
        overflow: hidden;
        background-image: url(../img/cross.png);
        background-repeat: no-repeat;
        background-size: 12px auto;
        background-position: 50% 50%;
        top: 0;
        right: 20px;
        cursor: pointer;
        transition: .5s opacity ease;

        &[disabled] {
            opacity: .3;
        }
    }
}

@keyframes loading {

    0% {
        background-color: $red;
        transform: translate(-50%,-50%) scale(1);
    }

    50% {
        background-color: #000;
        transform: translate(-50%,-50%) scale(1.3);
    }

    100% {
        background-color: $red;
        transform: translate(-50%,-50%) scale(1);
    }
}

.search-results {
    margin-bottom: 30px;

    ol {
        padding-top: 40px;
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
    }

    li {
        position: relative;
        @mixin cf;

        &:not(:last-child) {
            margin-bottom: 40px;

            &::after {
                content: '';
                display: block;
                top: 100%;
                left: 0;
                width: 100%;
                height: 10px;
                background-image: url(../img/grad-top.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: 100% 100%;
                position: absolute;
                opacity: .6;
            }
        }

        @media (--until-tablet) {

            &:not(:last-child)::after {
                opacity: .6;
                height: 5px;
                width: calc(100% - 15px);
                left: 15px;
            }
        }
    }

    p {
        margin-bottom: 0;
    }

    h3 {
        margin-bottom: 10px;

        a {
            color: $red;
            transition: .5s color ease;

            &:hover,
            &:focus {
                color: #000;
            }
        }
    }

    &-empty {
        padding-top: 30px;
        padding-bottom: 30px;
        font-family: $title;
        font-size: 22px;
        line-height: 28px;
    }
}

@keyframes spinner {

    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
