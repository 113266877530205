.standout {
    @mixin cf;
    padding-top: 10px;
    padding-bottom: 40px;

    &-list {
        list-style: none;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    &-item {
        float: left;
        width: calc(250 / 1024 * 100%);

        &:not(:last-child) {
            margin-right: calc(8 / 1024 * 100%);
        }

        @media (--until-desktop) {
            width: calc(380 / 768 * 100%);

            &:last-child,
            &:nth-last-of-type(2) {
                margin-top: 20px;
            }

            &:not(:last-child) {
                margin-right: 0;
            }

            &:nth-child(2n+2) {
                margin-left: calc(8 / 768 * 100%);
            }
        }

        @media (--mobile) {
            width: 100%;

            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }

    &-image {
        width: 100%;
        vertical-align: bottom;
        margin-bottom: 4px;
        transition: .5s opacity ease;
        backface-visibility: hidden;
    }

    &-title {
        font-family: $title;
        font-size: 16px;
        line-height: 26px;
        margin-top: 0;
        margin-bottom: 0;
    }

    &-link {
        display: block;
        color: $red;
        text-decoration: none;
        transition: .5s color ease;
        backface-visibility: hidden;

        &:hover,
        &:focus {
            color: #000;

            .standout-image {
                opacity: .6;
            }
        }
    }
}
