.lead {
    background: linear-gradient(to bottom, $red-dark 0%, $red 100%);
    color: #fff;
    @mixin cf;

    @media (--until-tablet) {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &-title {
        font-size: 42px;
        line-height: 104px;
        height: 104px;
        margin: 0;
        font-family: $title;
        float: left;

        @media (--until-tablet) {
            font-size: 32px;
            line-height: 42px;
            height: auto;
        }
    }

    &-duration {
        float: right;
        font-size: 22px;
        line-height: 102px;
        color: rgba(255,255,255,.7);
        display: block;

        @media (--until-tablet) {
            display: none;
        }
    }

    &-filters {
        background-color: $grey-light;
        min-height: 70px;
        padding: 15px 0;
    }
}
