.additional {
    padding-top: 75px;

    &-title {
        float: left;
        width: calc(200 / 1024 * 100%);
        text-align: right;
        font-size: 32px;
        line-height: 42px;
        margin-top: 0;
        font-family: $title;

        @media (--until-desktop) {
            float: none;
            width: 100%;
            text-align: left;
            margin-bottom: 10px;
        }
    }

    &-content {
        float: right;
        width: calc(793 / 1024 * 100%);
        @mixin cf;

        img {
            max-width: 100%;
            margin-bottom: 20px;
            float: left;
            width: calc(395 / 793 * 100%);

            @media (--mobile-large) {
                width: 30%;
            }

            @media (--mobile) {
                float: none;
                width: 100%;
                margin-bottom: 20px;
            }
        }

        @media (--until-desktop) {
            float: none;
            width: 100%;
        }
    }

    &-image,
    &-text {
        margin-bottom: 30px;
    }

    &-text {
        float: right;
        width: calc(376 / 793 * 100%);
        font-size: 14px;
        line-height: 23px;

        h3 {
            font-family: $title;
            margin-top: 0;
            margin-bottom: 14px;
            line-height: 37px;
            font-size: 28px;
            color: $red;
        }

        @media (--mobile-large) {
            width: 68%;
        }

        @media (--mobile) {
            width: 100%;
            float: none;
        }
    }

    &-more {
        display: block;
        clear: both;
        background-color: $red;
        color: #fff;
        text-align: center;
        height: 52px;
        line-height: 52px;
        text-decoration: none;
        transition: .5s background-color ease, .5s color ease;

        &:hover,
        &:focus {
            background-color: #000;
        }
    }
}
