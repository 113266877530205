.system {

    &-messages {
        background-color: #000;
        padding: 20px;
        text-align: center;
        color: #fff;
        margin: 0;

        h2 {
            margin-top: 0;
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 1;
        }

        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &-item {
            margin-bottom: 10px;
        }
    }

    &-links {
        background-color: $grey-light;
        padding: 10px;
        color: #000;

        ul {
            text-align: center;
        }

        li {
            display: inline-block;
            margin-right: 10px;
            margin-left: 10px;
        }

        a {
            color: $red;
            text-decoration: none;

            &:hover,
            &:focus {
                color: #000;
            }
        }
    }
}
