.news {

    &-list {
        padding-top: 40px;
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
    }

    &-item {
        padding-bottom: 30px;
        position: relative;
        @mixin cf;

        &:not(:last-child) {
            margin-bottom: 40px;

            &::after {
                content: '';
                display: block;
                top: 100%;
                left: 0;
                width: 100%;
                height: 10px;
                background-image: url(../img/grad-top.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: 100% 100%;
                position: absolute;
                opacity: .6;
            }
        }

        @media (--until-tablet) {

            &:not(:last-child)::after {
                opacity: .6;
                height: 5px;
                width: calc(100% - 15px);
                left: 15px;
            }
        }
    }

    &-contain {
        position: relative;
        @mixin cf;
    }

    &-image {
        float: left;
        width: calc(332 / 1024 * 100%);
        vertical-align: bottom;

        img {
            width: 100%;
        }

        @media (--until-tablet) {
            float: none;
            clear: both;
            width: 100%;
            margin-bottom: 20px;
        }
    }

    &-info {
        float: right;
        width: calc(663 / 1024 * 100%);

        @media (--until-tablet) {
            float: none;
            clear: both;
            width: 100%;
        }
    }

    &-link {
        display: block;
        float: left;
        clear: both;

        a {
            display: block;
            color: $red;
            font-family: $title;
            font-size: 16px;
            text-decoration: none;
            padding-right: 24px;
            background-image: url(../img/arrow-right-red.png);
            background-repeat: no-repeat;
            background-position: 100% 50%;
            background-size: 16px auto;
            transition: .5s background ease, .5s color ease;
        }
    }

    &-title {
        font-size: 32px;
        line-height: 42px;
        font-family: $title;
        margin-top: 0;
        margin-bottom: 10px;

        a {
            color: $red;
            transition: .5s color ease;
        }

        @media (--tablet) {
            font-size: 28px;
            line-height: 38px;
        }

        @media (--mobile) {
            font-size: 24px;
            line-height: 34px;
        }
    }

    &-content {
        padding-top: 30px;
        padding-bottom: 30px;
        @mixin cf;

        a {
            color: $red;
            transition: .5s color ease;
        }

        ul,
        ol {
            margin: 0 0 20px;
            padding: 0 0 0 20px;

            li {
                margin-bottom: 15px;
            }
        }

        ul {
            list-style: square;
        }

        ol {
            list-style: decimal;
        }

        h2 {
            margin-top: 30px;
            margin-bottom: 15px;
        }

        h3 {
            margin-top: 20px;
            margin-bottom: 5px;
        }

        strong,
        p.question {
            font-weight: bold;
        }
    }

    &-link a:hover,
    &-link a:focus {
        color: #000;
        background-image: url(../img/arrow-right-black.png);
    }

    &-title a:hover,
    &-title a:focus {
        color: #000;
    }

    &-content a:hover,
    &-content a:focus {
        color: #000;
    }

    &-date {
        display: block;
        font-family: $title;
        color: #000;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 24px;

        @media (--tablet) {
            font-size: 18px;
            line-height: 26px;
        }

        @media (--mobile) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &-summary {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    &-text {
        float: left;
        width: calc(635 / 1024 * 100%);
        font-size: 15px;
        line-height: 25px;

        @media (--until-tablet) {
            width: 100%;
            float: none;
        }
    }

    &-intro {
        font-family: $title;
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 20px;
        color: #000;

        @media (--until-tablet) {
            font-size: 20px;
            line-height: 31px;
        }
    }

    &-figure {
        float: right;
        width: calc(332 / 1024 * 100%);
        margin: 0;
        padding: 0;

        img {
            width: 100%;
            vertical-align: bottom;
            margin-bottom: 10px;
        }

        figcaption {
            font-size: 14px;
            font-family: $title;
            line-height: 24px;
        }

        @media (--until-tablet) {
            width: 100%;
            float: none;
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid $grey-light;
        }
    }
}
