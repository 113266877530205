.pagination {
    position: relative;
    padding-top: 25px;
    padding-bottom: 50px;
    height: 145px;

    @media (--mobile) {
        padding-bottom: 30px;
        height: 95px;
    }

    &-contain {
        position: relative;

        &::before,
        &::after {
            content: '';
            display: block;
            left: 0;
            width: 100%;
            height: 10px;
            background-repeat: no-repeat;
            background-position: 50% 0;
            background-size: 100% 100%;
            position: absolute;
            z-index: 6;
            opacity: .4;

            @media (--until-tablet) {
                opacity: .6;
                height: 5px;
                width: calc(100% - 15px);
                left: 15px;
            }
        }

        &::before {
            top: 0;
            background-image: url(../img/grad-top.png);
        }

        &::after {
            bottom: 0;
            background-image: url(../img/grad-bottom.png);
        }
    }

    &-list {
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        position: relative;
        height: 100%;
        @mixin cf;
        text-align: center;
        background: linear-gradient(to right, #fff 0%, #f0f0f0 50%, #fff 100%);
        display: flex;
        justify-content: center;
    }

    &-item {
        vertical-align: bottom;

        &:not(:last-child) {
            border-right: 1px solid rgba(0,0,0,.1);
        }
    }

    &-link {
        text-decoration: none;
        display: block;
        text-align: center;
        width: 70px;
        height: 70px;
        line-height: 70px;
        color: #000;
        transition: .5s color ease, .5s background-color ease, .5s border ease, .5s opacity ease;

        @media (--mobile) {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }

        &:hover,
        &:focus {
            background-color: #fff;
        }

        &--first,
        &--last,
        &--ellipsis,
        &--next,
        &--prev {
            text-indent: 115%;
            white-space: nowrap;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }

        &--first,
        &--last,
        &--next,
        &--prev {
            background-size: 18px auto;
        }

        &--first {
            background-image: url(../img/arrow-left-pagination.png);
        }

        &--last {
            background-image: url(../img/arrow-right-pagination.png);
        }

        &--prev {
            background-image: url(../img/arrow-left-pagination.png);
        }

        &--next {
            background-image: url(../img/arrow-right-pagination.png);
        }

        &--ellipsis {
            background-size: 11px auto;
            background-image: url(../img/ellipsis.png);
            background-color: transparent;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        &.is_current {
            background-color: #fff;
        }

        &.is_disabled {
            opacity: .5;
            background-color: transparent;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }
}
