/* ----------------------------------------------------
 *      use if you need to check for things
 *      in JS plugins - more reliable
 *      than window width, etc
 *---------------------------------------------------- */

.trigger-size {
    width: 0;
    height: 0;
    display: none;

    &.mobile {
        @media (--mobile) {
            display: block;
        }
    }

    &.mobile-large {
        @media (--mobile-large) {
            display: block;
        }
    }

    &.tablet {
        @media (--tablet) {
            display: block;
        }
    }

    &.desktop {
        @media (--desktop) {
            display: block;
        }
    }

    &.desktop-large {
        @media (--desktop-large) {
            display: block;
        }
    }

    &.desktop-wide {
        @media (--desktop-wide) {
            display: block;
        }
    }

    &.desktop-huge {
        @media (--desktop-huge) {
            display: block;
        }
    }
}

.hidden {
    display: none;
    visibility: hidden;
}

.vh {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.vh.focusable:active,
.vh.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    z-index: 9999;
}

.invisible {
    visibility: hidden;
}
