table {
    border-collapse: collapse;
}

caption {
    padding: 0;
    text-align: left;
    caption-side: bottom;
}

ol,
ul,
dl {
    margin: 0;
    list-style: none;
    padding: 0;
}

dt {
    font-weight: bold;
}

dd {
    margin: 0;
}

a {
    background-color: transparent;
}

img {
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
    font: inherit;
    margin: 0;
}

input,
button,
select,
textarea {
    line-height: inherit;
}

optgroup {
    font-weight: bold;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

textarea {
    overflow: auto;
    resize: none;
}

[type='checkbox'],
[type='radio'],
[type='number'],
[type='search'],
button,
[type='button'],
[type='reset'],
[type='submit'] {
    box-sizing: border-box;
    padding: 0;
    appearance: none;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    appearance: listbox;
}

abbr[title] {
    border: 0;
    text-decoration: underline;
    cursor: help;
}

b,
strong {
    font-weight: inherit;
}

dfn {
    font-style: normal;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

code,
kbd,
pre,
samp {
    font-family: $monospace;
    font-size: 16px;
}

pre {
    margin: 0;
    overflow: auto;
}

figure {
    margin: 0;
}

html {
    text-size-adjust: 100%;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

progress {
    vertical-align: baseline;
}

address {
    margin: 0;
    font-style: normal;
    line-height: inherit;
}

a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
    touch-action: manipulation;
}
