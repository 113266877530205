html {
    font-size: 16px;
}

body {
    font-family: $sans;
    font-size: inherit;
    font-weight: $fwn;
    line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title;
    font-weight: $fwn;
    margin: 0;
}

h1 {
    font-size: 42px;
    line-height: calc(48 / 42 * 100%);
}

h2 {
    font-size: 24px;
    line-height: calc(30 / 24 * 100%);
    margin-top: 20px;
    margin-bottom: 10px;
}

h3 {
    font-size: 22px;
    line-height: calc(28 / 22 * 100%);
}

h4 {
    font-size: 18px;
    line-height: calc(24 / 18 * 100%);
}

p {
    margin-top: 0;
    margin-bottom: 20px;
}

a {
    text-decoration: none;
}

.noscript-warning {
    margin: 0;
    text-align: center;
    padding: 20px;
    background-color: #666;
    color: #fff;
}

.old-browser {
    padding: 20px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    line-height: 22px;
    display: none;

    p {
        margin: 0 0 30px;

        &:last-child {
            margin: 0;
        }
    }

    &.is_visible {
        display: block;
    }
}

a,
button,
input,
select,
textarea,
label {

    &:focus {
        outline: 2px solid $a11y;
    }

    &:focus.no-focus {
        outline: 0;
    }
}
