.productions {

    &-contain {
        padding-top: 30px;
    }

    &-list {
        list-style: none;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
    }

    &-item {
        flex: 0 0 calc(353 / 1100 * 100%);
        margin-bottom: 25px;
        margin-right: calc(20 / 1100 * 100%);

        &:nth-child(3n+3) {
            margin-right: 0;
        }
    }

    &-link {
        text-decoration: none;
        display: block;
        height: 100%;
        @mixin cf;
    }

    &-title,
    &-year {
        line-height: 16px;
        margin-top: 8px;
        margin-bottom: 0;
        transition: .5s color ease;
    }

    &-title {
        float: left;
        width: 88%;
        color: $red;
        font-size: 16px;
        font-family: $title;

        .productions-link:hover &,
        .productions-link:focus & {
            color: #000;
        }
    }

    &-year {
        float: right;
        color: $grey;
        font-size: 13px;
        display: block;

        .productions-link:hover &,
        .productions-link:focus & {
            color: rgba(0,0,0,.2);
        }
    }

    &-image {

        img {
            vertical-align: bottom;
            width: 100%;
            transition: .5s opacity ease;
            backface-visibility: hidden;

            .productions-link:hover &,
            .productions-link:focus & {
                opacity: .7;
            }
        }
    }

    &-landing {
        padding-top: 30px;
    }

    @media (--tablet) {

        &-title {
            font-size: 14px;
        }

        &-year {
            font-size: 12px;
        }
    }

    @media (--mobile-large) {

        &-item {
            flex: 0 0 calc(230 / 480 * 100%);
            margin-right: 0;

            &:nth-child(3n+3) {
                margin-right: calc(20 / 480 * 100%);
            }

            &:nth-of-type(odd) {
                margin-right: calc(20 / 480 * 100%);
            }

            &:nth-of-type(even) {
                margin-right: 0;
            }
        }
    }

    @media (--mobile) {

        &-item {
            flex: 0 0 100%;
            float: none;
            margin-bottom: 30px;

            &:not(:nth-of-type(3n+3)) {
                margin-right: 0;
            }
        }
    }
}
