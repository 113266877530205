.accessibility-nav {
    position: absolute;
    top: 0;
    left: 0;

    a {
        color: $red;
    }
}

.header {
    background-color: $grey-light;
    height: 66px;
    line-height: 34px;
    padding-top: 10px;

    &-logo {
        /* width: 131px; */
        height: 45px;
        text-indent: 115%;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        float: left;
        background-image: url(../img/logo.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        width: 140px;
    }

    &-strap {
        float: right;
        font-size: 18px;
        font-family: $title;
        line-height: 45px;

        @media (--tablet) {
            font-size: 14px;
        }

        @media (--until-tablet) {
            display: none;
        }
    }
}

.nav {
    background-color: $grey-light;
    border-top: 1px solid rgba(0,0,0,.1);
    position: relative;

    &::before {
        content: '';
        display: block;
        top: 100%;
        left: 0;
        width: 100%;
        height: 10px;
        background-image: url(../img/grad-top.png);
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: 100% 100%;
        position: absolute;
        z-index: 11;
        opacity: .8;

        @media (--until-tablet) {
            opacity: .6;
            height: 5px;
            width: calc(100% - 15px);
            left: 15px;
        }
    }

    @media (--until-tablet) {
        border-top: 0;
        transition: .5s height ease;
    }

    &-contain {
        max-width: 1440px;
        margin: 0 auto;

        @media (--from-desktop-wide) {
            padding-left: 135px;
            padding-right: 135px;
        }

        @media (--desktop-large) {
            padding-left: 35px;
            padding-right: 35px;
        }

        @media (--until-desktop) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-status {
        font-size: 16px;
        display: block;
        height: 100%;
        transition: .5s opacity ease;
        position: absolute;
        top: 0;
        right: 26px;
        line-height: 45px;

        &--open {
            opacity: 1;

            &::before {
                content: '';
                display: block;
                height: 3px;
                background-color: #000;
                width: 18px;
                border-radius: 2px;
                position: absolute;
                right: -26px;
                top: 21px;
                transition: .5s all ease;
                transform-origin: 50% 50%;
                backface-visibility: hidden;
            }

            .is_pressed & {
                opacity: 0;
            }
        }

        &--close {
            opacity: 0;

            .is_pressed & {
                opacity: 1;
            }
        }
    }

    &-toggle {
        position: absolute;
        top: 0;
        right: 15px;
        height: 100%;
        border: 0;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
        transition: .5s color ease;
        padding-right: 24px;
        padding-left: 50px;
        font-family: $title;

        &::before,
        &::after {
            content: '';
            display: block;
            height: 3px;
            background-color: #000;
            width: 18px;
            border-radius: 2px;
            position: absolute;
            right: 0;
            transition: .5s all ease;
            transform-origin: 50% 50%;
            backface-visibility: hidden;
        }

        &::before {
            top: 14px;
        }

        &::after {
            bottom: 13px;
        }

        &:hover,
        &:focus {
            color: $red;
            outline: 0;

            &::before,
            &::after,
            .nav-status--open::before {
                background-color: $red;
            }
        }

        .is_open & {

            &:hover::before,
            &:focus::after {
                background-color: $red;
            }
        }

        &.is_pressed {

            &::before {
                transform: rotate(45deg);
                top: 21px;
                right: 4px;
            }

            &::after {
                transform: rotate(-45deg);
                right: 4px;
                bottom: 21px;
            }
        }

        @media (--from-tablet) {
            display: none;
        }
    }

    &-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;

        @media (--until-tablet) {
            flex-wrap: wrap;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 15px;
            overflow: hidden;
        }
    }

    &-item {
        height: 66px;
        line-height: 66px;
        position: relative;
        flex: 0 1 auto;

        &:not(:last-of-type) {
            border-right: 1px solid rgba(0,0,0,.1);
        }

        &:nth-last-of-type(2) {
            flex: 1 1 auto;
        }

        @media (--until-tablet) {
            height: 56px;
            line-height: 56px;
            flex: 0 0 100%;

            &:nth-last-of-type(2) {
                flex: 0 0 100%;
            }

            &:not(:last-of-type) {
                border-right: 0;
            }

            &:last-of-type {
                padding-top: 15px;
                height: 71px;
            }

            &:first-child,
            &:last-child {
                border-top: 0;
            }
        }
    }

    &-link {
        display: block;
        text-decoration: none;
        padding-left: 35px;
        padding-right: 35px;
        color: #000;
        background-color: $grey-light;
        transition: .5s background-color ease, .5s color ease;
        font-size: 16px;
        height: 100%;
        font-family: $title;

        &:hover,
        &:focus,
        &.is_active {
            background-color: #fff;
        }

        @media (--desktop) {
            padding-left: 25px;
            padding-right: 25px;
        }

        @media (--tablet) {
            padding-left: 20px;
            padding-right: 20px;
            font-size: 12px;
        }

        @media (--until-tablet) {
            padding-left: 15px;
            padding-right: 30px;
            position: relative;
            background-color: transparent;
            background-image: none;
            border-top: 1px solid rgba(0,0,0,.1);

            &::after {
                content: '';
                display: block;
                height: 100%;
                width: 20px;
                background-color: transparent;
                background-image: url(../img/arrow-right-red.png);
                background-position: 100% 50%;
                background-repeat: no-repeat;
                background-size: auto 16px;
                position: absolute;
                right: 15px;
                top: 0;
            }

            &:hover,
            &:focus {
                background-color: transparent;
                color: $red;
            }
        }
    }
}

.all4-header {
    border-bottom: 1px solid rgba(0,0,0,.1);
    width: 100%;
    overflow: hidden;
    background-color: #292929;
    .nav {
        background-color: #292929;
        border-top: 0;

        &::before {
            display: none;
        }
    }
}
