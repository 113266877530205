@define-mixin cf {

    &::before,
    &::after {
        content: ' ';
        display: table;
    }

    &::after {
        clear: both;
    }
}

@define-mixin imgreplace $width, $height, $img, $bgsize: contain, $display: block, $imgpos: 0 0, $imgrepeat: no-repeat, $colour: transparent {
    display: $display;
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
    width: $width;
    height: $height;
    background-color: $colour;
    background-image: url($img);
    background-position: $imgpos;
    background-repeat: $imgrepeat;
    background-size: $bgsize;
}

@define-mixin arrow $width: 20px, $height: 20px, $color: #000, $direction: down {
    width: 0;
    height: 0;

    @if $direction == down {
        border-left: $width/2 solid transparent;
        border-right: $width/2 solid transparent;
        border-top: $height solid $color;
    }

    @if $direction == up {
        border-left: $width/2 solid transparent;
        border-right: $width/2 solid transparent;
        border-bottom: $height solid $color;
    }

    @if $direction == left {
        border-top: $height/2 solid transparent;
        border-bottom: $height/2 solid transparent;
        border-right: $width solid $color;
    }

    @if $direction == right {
        border-top: $height/2 solid transparent;
        border-bottom: $height/2 solid transparent;
        border-left: $width solid $color;
    }
}
