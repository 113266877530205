.feature {
    background-color: #fff;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    @media (--until-tablet) {
        margin-bottom: 0;
    }

    &-info {
        color: #fff;
        position: absolute;
        z-index: 3;
    }

    &-title,
    &-tag,
    &-desc {
        font-family: $title;
    }

    &-title {
        font-weight: $fwn;
        margin-top: 0;
        font-family: $title;
    }

    &-desc {
        margin: 0;
        line-height: 30px;
        display: inline-block;
        max-width: calc(900 / 1140 * 100%);
    }

    &-tag {
        background-color: $red;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 15px;
        transition: .5s background-color ease, .5s color ease;
        vertical-align: top;
    }

    &-image {
        position: relative;

        img {
            width: 100%;
            vertical-align: bottom;
            position: relative;
            z-index: 1;
        }

        &::after {
            content: '';
            display: block;
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            min-height: 50%;
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
            transition: .5s opacity ease;
            opacity: .8;
        }
    }

    @media (--tablet) {

        &-title {
            font-size: 32px;
        }

        &-desc {
            line-height: 1.4;
            font-size: 16px;
        }
    }

    @media (--until-tablet) {
        width: 100%;
        margin-right: 0;
        float: none;
        clear: both;
        margin-bottom: 0;

        &-title {
            font-size: 32px;
            line-height: 1;
            width: 100%;
            clear: both;
            margin-bottom: 10px;
        }

        &-date {
            padding: 0 0 15px;
            position: relative;
            display: block;
        }

        &-tag {
            font-size: 16px;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 0;
        }

        &-desc {
            background-color: #fff;
            padding: 0;
            line-height: 1.4;
            height: auto;
            color: #000;
            font-size: 14px;
            margin-top: 0;
        }

        &:hover,
        &:focus {

            .feature-tag {
                color: #fff;
                background-color: #000;
            }
        }
    }

    &--large,
    &--small {
        position: relative;
        margin-bottom: 10px;
        display: block;
        text-decoration: none;

        @media (--until-tablet) {
            margin-bottom: 15px;

            .feature-info {
                position: relative;
                top: auto;
                bottom: auto;
                right: auto;
                width: calc(100% - 30px);
                z-index: 5;
                margin-top: -78px;
                @mixin cf;
            }

            &.feature--production .feature-info {
                margin-top: -57px;
            }

            &.feature--single .feature-info {
                margin-top: -47px;
            }

            &.feature--home .feature-info {
                margin-top: -60px;
            }

            &.feature--production.feature--single .feature-info {
                position: absolute;
                bottom: 0;
                left: 15px;
            }

            &.feature--production.feature--single .feature-title {
                overflow: visible;
                text-overflow: normal;
                height: auto;
                line-height: 1.3;
                white-space: normal;
            }

            .feature-desc {
                width: 100%;
                max-width: 100%;
                display: block;
                font-size: 16px;
            }
        }
    }

    &--small {
        width: calc(678 / 1366 * 100%);
        float: left;

        &:nth-child(even) {
            margin-right: calc(10 / 1366 * 100%);
        }

        .feature-info {
            bottom: 22px;
            left: 28px;
            right: 28px;
        }

        .feature-title {
            font-size: 32px;
            margin-bottom: 0;
            float: left;
            width: 66%;

            @media (--desktop) {
                font-size: 26px;
            }

            @media (--mobile) {
                width: 56%;
            }
        }

        .feature-tag {
            font-size: 17px;
            margin-top: 0;
            margin-bottom: 5px;
            float: none;
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .feature-desc {
            display: none;
        }

        @media (--tablet) {
            width: calc(507 / 1024 * 100%);

            &:nth-child(even) {
                margin-right: calc(10 / 1024 * 100%);
            }

            .feature-tag {
                clear: both;
                float: left;
            }

            .feature-info {
                bottom: 20px;
                left: 20px;
                right: 20px;
            }

            .feature-title {
                font-size: 22px;
            }

            .feature-tag {
                margin-bottom: 0;
            }

            .feature-tag,
            .feature-desc {
                font-size: 15px;
                float: left;
                clear: both;
            }
        }

        @media (--until-tablet) {
            width: 100%;
            float: none;
            margin-bottom: 15px;

            &:nth-child(even) {
                margin-right: 0;
            }

            .feature-title {
                font-size: 16px;
                position: absolute;
                bottom: 0;
                left: 0;
                float: none;
                clear: left;
                height: auto;
            }

            .feature-tag {
                font-size: 14px;
                padding-left: 8px;
                padding-right: 8px;
                line-height: 30px;
                height: 30px;
                margin-bottom: 0;
            }

            .feature-tag,
            .feature-desc {
                float: left;
                display: block;
                clear: both;
            }

            .feature-desc {
                font-size: 16px;
            }

            &.feature--home .feature-info {
                margin-top: 0;
                position: absolute;
                bottom: 15px;
                right: 15px;
                left: 15px;
            }
        }
    }

    &--large,
    &--poster {
        width: 100%;

        .feature-info {
            bottom: 50px;

            @media (--from-desktop-wide) {
                left: 170px;
                right: 170px;
            }

            @media (--desktop-large) {
                left: 70px;
                right: 70px;
                bottom: 35px;
            }

            @media (--desktop) {
                left: 35px;
                right: 35px;
                bottom: 35px;
            }

            @media (--tablet) {
                left: 20px;
                right: 20px;
                bottom: 0;
                padding-bottom: 20px;
            }

            @media (--until-tablet) {
                left: 15px;
                right: 15px;
                bottom: 0;
                width: calc(100% - 30px);
            }
        }

        .feature-title {
            font-size: 65px;
            margin-bottom: 15px;
            font-family: $title;

            @media (--desktop) {
                font-size: 42px;
            }

            @media (--tablet) {
                font-size: 56px;
            }

            @media (--until-tablet) {
                font-size: 22px;
            }
        }

        .feature-desc,
        .feature-tag {
            font-size: 22px;
        }

        .feature-tag {
            margin-right: 10px;
        }

        @media (--until-desktop-large) {

            .feature-desc,
            .feature-tag {
                font-size: 18px;
            }
        }

        @media (--until-tablet) {

            .feature-tag,
            .feature-desc {
                clear: both;
                display: block;
                float: left;
                font-size: 16px;
            }
        }
    }

    a&--large:hover,
    a&--large:focus,
    a&--small:hover,
    a&--small:focus {
        outline: 0;

        .feature-tag {
            background-color: #fff;
            color: #000;
        }

        .feature-image::after {
            opacity: 1;
        }
    }

    &--poster {

        .feature-info {
            @media (--mobile) {
                padding-bottom: 0;
            }
        }

        .feature-title {

            @media (--mobile-large) {
                font-size: 42px;
            }

            @media (--mobile) {
                font-size: 32px;
            }
        }
    }

    &--production.feature--large {
        margin-bottom: 0;
    }
}
