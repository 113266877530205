.sidebar {
    padding-left: 30px;
    position: relative;
    float: right;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -14px;
        top: 0;
        height: 100%;
        width: 14px;
        background-image: url(../img/grad-vert-left.png);
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: auto 100%;
    }

    @media (--tablet) {
        width: 34%;
    }

    @media (--until-tablet) {
        float: none;
        clear: both;
        width: 100%;
        padding-left: 0;
        padding-top: 25px;

        &::before {
            left: 0;
            top: -10px;
            height: 10px;
            width: 100%;
            background-image: url(../img/grad-top.png);
            background-position: 50% 0;
            background-size: 100% auto;
            opacity: .6;
        }
    }
}
