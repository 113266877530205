.social {
    background-color: #000;
    padding-top: 25px;
    padding-bottom: 25px;

    @media (--until-tablet) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &-title {
        color: #fff;
        font-size: 20px;
        line-height: 36px;
        font-family: $title;
        height: 36px;
        background-color: $red;
        padding-left: 6px;
        padding-right: 6px;
        font-weight: normal;
        float: left;
        margin-top: 0;
        margin-bottom: 0;

        @media (--until-tablet) {
            font-size: 16px;
            height: 30px;
            line-height: 30px;
            clear: both;
            margin-bottom: 15px;
        }
    }

    &-tweet {
        float: right;
        width: calc(850 / 1024 * 100%);

        @media (--until-tablet) {
            float: left;
            clear: left;
            width: 100%;
        }
    }

    &-content {
        color: #fff;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 12px;
        font-family: $title;

        a {
            color: #fff;
            text-decoration: underline;
            transition: .5s color ease;

            &:hover,
            &:focus {
                color: $red;
            }
        }

        @media (--until-tablet) {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 10px;
        }
    }

    &-meta {
        color: rgba(255,255,255,.5);
    }

    &-timestamp {
        margin-right: 10px;
        display: inline-block;
        line-height: 25px;
        height: 25px;

        @media (--until-tablet) {
            font-size: 14px;
        }
    }

    &-link {
        color: rgba(255,255,255,.5);
        text-decoration: none;
        transition: .5s color ease;

        &:hover,
        &:focus {
            color: #fff;
        }
    }

    &-direction {
        text-indent: 115%;
        white-space: nowrap;
        overflow: hidden;
        width: 25px;
        height: 25px;
        display: inline-block;
        vertical-align: middle;
        position: relative;

        &::before {
            display: block;
            color: rgba(255,255,255,.5);
            width: 25px;
            height: 25px;
            text-indent: 0;
            font-family: $icons;
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
            line-height: 25px;
            transition: .5s color ease;
        }

        &:hover,
        &:focus {

            &::before {
                color: #fff;
            }
        }

        &--like::before {
            content: '\e801';
        }

        &--retweet::before {
            content: '\e800';
        }
    }
}
