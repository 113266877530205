.video {

    &-play {
        position: absolute;
        bottom: 42px;
        left: 0;
        display: block;
        background-color: $red;
        color: #fff;
        width: 84px;
        height: 84px;
        background-image: url(../img/play.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 26px auto;
        border: 0;
        text-indent: 115%;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        transition: .5s background-color ease;
        z-index: 2;

        &:hover,
        &:focus {
            background-color: #000;
        }
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: .5s opacity linear, .5s z-index linear;
        z-index: 1;
        opacity: 1;
        margin: 0;

        &.is_hidden {
            z-index: -1;
            opacity: 0;
        }

        &::after {
            display: none;
        }

        img {
            width: 100%;
            vertical-align: bottom;
        }
    }

    &-tag {
        display: block;
        height: 36px;
        line-height: 36px;
        background-color: #fff;
        color: #000;
        font-size: 16px;
        padding: 0 10px;
        font-family: $title;
        position: absolute;
        left: 36px;
        bottom: 0;
        z-index: 2;
    }
}
