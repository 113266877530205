.shorts {

    &-list {
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        @mixin cf;
    }

    &-item {
        float: left;
        width: calc(502 / 1024 * 100%);
        margin-bottom: 30px;

        &:nth-child(odd) {
            margin-right: calc(20 / 1024 * 100%);
        }

        @media (--until-tablet) {
            float: none;
            width: 100%;
            padding-top: 0;

            &:nth-child(odd) {
                margin-right: 0;
            }
        }
    }

    &-title {
        font-size: 24px;
        line-height: 35px;
        font-family: $title;
        margin-top: 0;
        margin-bottom: 5px;
        float: left;
        clear: left;

        a {
            color: $red;
            transition: .5s color ease;

            &:hover,
            &:focus {
                color: #000;
            }
        }
    }

    &-image {
        margin-bottom: 15px;

        .individual-short & {
            margin-bottom: 0;
        }

        a {
            display: block;
            height: 100%;
            position: relative;

            &::after {
                content: '';
                display: block;
                background-color: $red;
                color: #fff;
                width: 42px;
                height: 42px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-image: url(../img/play.png);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 14px auto;
                transition: .5s background-color ease;
            }
        }

        a:hover,
        a:focus {

            &::after {
                background-color: #000;
            }
        }

        img {
            width: 100%;
            vertical-align: bottom;
            transition: .5s opacity ease;
            backface-visibility: hidden;
        }
    }

    &-duration {
        display: inline-block;
        font-size: 13px;
        color: $grey;
        padding-left: 10px;
        line-height: 35px;
        height: 35px;
    }

    &-desc {
        font-size: 14px;
        line-height: 21px;
        color: #000;
        margin-bottom: 0;
        clear: both;
    }

    &-video {
        height: 0;
        position: relative;
        overflow: hidden;
        padding-top: calc(56.25% + 42px);
        margin-bottom: 20px;

        > .video-js {
            position: absolute;
            width: 100%;
            height: calc(100% - 42px);
            top: 0;
            left: 0;
            border: 0;
            background-color: #fff;
        }

        @media (--until-tablet) {
            margin-bottom: 20px;
        }
    }

    &-play {
        width: 84px;
        height: 84px;
        background-size: 26px auto;
    }

    &-content {
        @mixin cf;
        padding-bottom: 30px;

        &.pad-top {
            padding-top: 50px;

            @media (--until-tablet) {
                padding-top: 30px;
            }
        }

        @media (--from-desktop-wide) {
            padding-left: 0;
            padding-right: 0;
        }
        

        @media (--desktop) {
            padding-left: 35px;
            padding-right: 35px;
        }

        @media (--tablet) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @media (--until-tablet) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &-text {
        float: left;
        width: calc(640 / 1024 * 100%);
        font-size: 15px;
        line-height: 25px;

        &:last-child {
            margin-bottom: 0;
        }

        @media (--until-tablet) {
            width: 100%;
            float: none;
            padding-bottom: 25px;
        }
    }

    &-aside {
        width: calc(342 / 1024 * 100%);
        float: right;

        .production--crew {
            padding-bottom: 0;
            border-bottom: 0;
        }
    }

    &-intro {
        font-family: $title;
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 20px;

        @media (--tablet) {
            font-size: 20px;
            line-height: 28px;
        }

        @media (--until-tablet) {
            font-size: 18px;
            line-height: 26px;
        }
    }

    &-link {
        display: block;
        text-decoration: none;
        height: 100%;

        &-image {

            &::after {
                background-color: #000;
            }

            img {
                opacity: .5;
            }
        }
    }

    &-landing {
        padding-top: 30px;
    }

    &-individual {
        padding-top: 30px;
        @mixin cf;

        &.shorts-item {
            float: none;
            width: auto;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0;
            padding-right: 0;

            @media (--until-tablet) {
                margin-bottom: 0;
            }

            &:nth-child(odd) {
                margin-right: auto;
            }
        }
    }
}
