.footer {
    background: linear-gradient(to bottom, $grey-lightish 20%, #fff 70%);
    position: relative;
    border-bottom: 10px solid #000;
    padding-top: 40px;
    padding-bottom: 40px;

    &::before {
        content: '';
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background-image: url(../img/grad-top.png);
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: 100% 100%;
        position: absolute;
        z-index: 4;
        opacity: .8;

        @media (--until-tablet) {
            opacity: .6;
            height: 5px;
            width: calc(100% - 15px);
            left: 15px;
        }
    }

    @media (--until-tablet) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &-nav {
        float: left;

        @media (--until-tablet) {
            @mixin cf;
            float: none;
            clear: both;
            margin-bottom: 35px;
        }
    }

    &-link {
        color: #000;
        text-decoration: none;
        transition: .5s color ease;
        line-height: 24px;
        height: 24px;
        display: inline-block;
        font-family: $title;

        &:hover,
        &:focus {
            color: $red;
        }

        @media (--from-tablet) {

            &:not(:last-of-type) {
                margin-right: 20px;
                padding-right: 20px;
                position: relative;
            }

            &:not(:last-of-type)::after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 1px;
                background-color: rgba(0,0,0,.15);
            }
        }

        @media (--until-tablet) {
            font-size: 14px;
            float: left;

            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
    }

    &-social {
        float: right;
        @mixin cf;

        @media (--until-tablet) {
            float: none;
            clear: both;
        }
    }

    &-title {
        font-size: 16px;
        font-family: $title;
        float: left;
        display: block;
        line-height: 24px;
        height: 24px;

        @media (--until-tablet) {
            font-size: 14px;
        }
    }

    &-list {
        float: left;
        @mixin cf;
        list-style: none;
        margin: 0;
        padding: 0;

        @media (--until-tablet) {
            float: right;
        }
    }

    &-item {
        float: left;
        margin-left: 20px;

        @media (--until-tablet) {
            margin-left: 15px;
        }
    }

    &-direction {
        display: block;
        text-indent: 115%;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        height: 24px;
        width: 24px;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            text-indent: 0;
            color: $red;
            font-family: $icons;
            transition: .5s color ease;
            font-size: 23px;
            line-height: 24px;
            height: 24px;
            width: 24px;
            text-align: center;
        }

        &:hover,
        &:focus {

            &::before {
                color: #000;
            }
        }

        &--facebook::before {
            content: '\f09a';
        }

        &--twitter::before {
            content: '\f099';
        }

        &--youtube::before {
            content: '\f166';
        }

        &--instagram::before {
            content: '\f16d';
        }

        &--pinterest::before {
            content: '\f0d2';
        }
    }
}
