.parental {
    position: relative;

    .parental-modal & {
        background-color: rgba(0,0,0,.6);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        transition: .5s opacity ease, .5s z-index ease;

        &.is_active {
            z-index: 200;
            opacity: 1;
        }
    }

    &-text--enable,
    &-text--disable {
        display: none;

        &.is_active {
            display: block;
        }
    }

    &-error-messages {
        width: 100%;
        clear: both;
        color: #fff;
        background-color: $red;
        padding: 10px;
        font-size: 14px;
        display: none;
        margin-bottom: 20px;

        &.is_visible {
            display: block;
        }

        .parental-modal & {
            width: calc(100% - 60px);
            margin-left: 30px;
            margin-right: 30px;
        }

        @media (--until-tablet) {

            .parental-modal & {
                width: calc(100% - 20px);
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }

    &-error-message {
        display: block;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-external-link {
        display: inline-block;
        color: $red;
        transition: .5s color ease;
        font-size: 14px;
        margin-right: 10px;

        &:hover,
        &:focus {
            color: $black;
        }
    }

    &-button,
    &-close {
        border: 0;
        margin: 0;
        appearance: none;
        color: #fff;
        text-align: center;
        cursor: pointer;
    }

    &-button,
    &-close,
    &-label,
    &-pass,
    &-radio {
        font-size: 18px;
        font-family: $title;
    }

    &-pass {
        border: 1px solid $grey;
        padding: 0 10px;
        height: 40px;
        width: 40px;
        line-height: 40px;
        transition: .5s border ease;
        color: #000;
        text-align: center;

        &:hover {
            border-color: $red;
            outline: 0;
        }

        &:focus {
            border-color: #000;
            outline: 2px solid #000;
        }

        &.is_invalid {
            animation: .5s infinite pulse-border ease;
        }

        @media (--mobile) {
            float: left;
            margin-left: 4px;
            margin-bottom: 10px;

            .parental-modal & {
                float: none;
                display: inline-block;
            }
        }
    }

    &-tooltip {
        position: relative;
        padding: 0 0 0 10px;
        display: inline-block;
        font-family: $sans;

        &::before {
            content: '';
            border-radius: 50%;
            width: 20px;
            height: 20px;
            background-color: $red;
            background-image: url(../img/i-white.png);
            background-repeat: no-repeat;
            background-size: auto 14px;
            background-position: 50% 44%;
            color: $grey-light;
            text-align: center;
            font-size: 16px;
            display: block;
            line-height: 20px;
        }

        &-content {
            position: absolute;
            opacity: 0;
            z-index: -1;
            padding: 3px 6px;
            background-color: #fffde2;
            color: $black;
            font-size: 13px;
            transition: .5s opacity ease, .5s z-index ease;
            bottom: 110%;
            left: 100%;
            width: 200px;
            height: 100%;
        }
    }

    &-checkbox {
        position: absolute;
        width: 2px;
        height: 2px;
        opacity: 0;
        clip: rect(0 0 0 0);
        overflow: hidden;
        white-space: nowrap;
        appearance: none;
        top: 0;
        left: 0;
        display: block;
        border: 0;
        background: transparent;

        &-contain {
            overflow: hidden;
            width: 100%;
            height: 100%;
            position: relative;
        }

        &:focus {
            outline: 0;

            & + label::before {
                border-width: 2px;
                border-color: $red;
            }
        }

        & + label {
            display: block;
            height: 20px;
            line-height: 20px;
            cursor: pointer;
            position: relative;
            padding-left: 30px;
            text-align: left;
            font-size: 14px;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 20px;
                text-align: center;
                border-radius: 2px;
            }

            &::before {
                border: 1px solid $grey;
                transition: .5s border-color ease;
                background-color: #fff;
            }

            &::after {
                background-image: url(../img/icon-checked.png);
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: auto 12px;
                opacity: 0;
                transition: .5s opacity ease;
                backface-visibility: hidden;
            }

            &:hover::before,
            &:focus::before {
                border-color: $red;
            }
        }

        & + label a {
            color: $red;
            transition: .5s color ease;

            &:hover,
            &:focus {
                color: $black;
            }
        }

        &.is_invalid {

            & + label::before {
                animation: .5s infinite pulse-border ease;
            }
        }

        @media (--until-tablet) {
            width: 40px;
            height: 40px;

            & + label {
                font-size: 12px;
                height: 40px;
                line-height: 20px;
                padding-left: 54px;
                vertical-align: middle;
            }

            & + label::before,
            & + label::after {
                width: 40px;
                height: 40px;
            }
        }
    }

    &-radio {
        position: absolute;
        width: 2px;
        height: 2px;
        opacity: 0;
        /*clip: rect(0 0 0 0);*/
        overflow: hidden;
        white-space: nowrap;
        appearance: none;
        top: 0;
        left: 0;
        display: block;
        border: 0;
        background: transparent;

        &-contain {
            overflow: hidden;
            width: 100%;
            height: 100%;
            position: relative;
        }

        &:focus {
            outline: 0;
        }

        & + label {
            display: block;
            height: 20px;
            line-height: 20px;
            cursor: pointer;
            position: relative;
            padding-left: 32px;
            text-align: left;
            font-size: 14px;
            color: $red;
        }

        & + label::before,
        & + label::after {
            content: '';
            display: block;
            position: absolute;
            text-align: center;
            border-radius: 50%;
        }

        & + label::before {
            border: 1px solid $grey;
            transition: .5s border-color ease;
            background-color: #fff;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            z-index: 1;
        }

        & + label::after {
            opacity: 0;
            transition: .5s opacity ease;
            top: 4px;
            left: 4px;
            height: 12px;
            width: 12px;
            z-index: 2;
            background-color: $red;
        }

        & + label:hover::before {
            border-color: $red;
        }

        & + label:focus::before {
            border-color: #000;
            outline: 2px solid #000;
        }

        &.is_invalid {

            & + label::before {
                animation: .5s infinite pulse-border ease;
            }
        }

        @media (--until-tablet) {

            & + label {
                font-size: 12px;
                min-height: 26px;
            }
        }
    }

    &-checkbox:checked + label {

        &::before {
            border-color: #000;
            border-width: 2px;
        }

        &::after {
            opacity: 1;
        }
    }

    &-checkbox:checked {

        &:focus + label::before {
            border-color: $red;
        }

        &:focus + label::after {
            color: $red;
            opacity: .8;
        }
    }

    &-radio:checked + label {

        &::before {
            border-color: #000;
        }

        &::after {
            opacity: 1;
        }
    }

    &-radio:checked {

        &:focus + label::before {
            border-color: $red;
        }

        &:focus + label::after {
            background-color: $grey;
        }
    }

    &-link {
        color: $red;
        transition: .5s color ease;
        position: relative;
        font-family: $sans;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $black;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }

        &--small {
            font-size: 14px;
            padding-right: 16px;
            display: none;

            .parental-modal & {
                display: initial;
            }

            &::after {
                background-image: url(../img/chevron-right-red.png);
                background-size: auto 14px;
                width: 16px;
                height: 16px;
            }
        }
    }

    &-button {
        padding: 0 30px;
        line-height: 43px;
        height: 43px;
        display: block;
        width: 100%;
        background-color: $red;
        transition: .5s background-color ease;

        .parental-modal & {
            display: inline-block;
            width: auto;
        }

        &:hover,
        &:focus {
            background-color: $black;
        }

        &--link {
            padding: 0;
            background-color: transparent;
            color: $red;
            font-size: 14px;
            clear: both;
            transition: .5s color ease;
            font-family: $sans;

            &:hover,
            &:focus {
                background-color: transparent;
                color: $black;
            }

            &-off {
                clear: none;
            }
        }

        &--accept,
        &--play {
            padding-left: 73px;
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 43px;
                height: 43px;
                background-color: $red;
                border-right: 2px solid #fff;
                background-image: url(../img/play.png);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 16px auto;
                position: absolute;
                left: 0;
                top: 0;
                transition: .5s background-color ease;
            }
        }

        &--accept:hover::before,
        &--accept:focus::before,
        &--play:hover::before,
        &--play:focus::before {
            background-color: $black;
        }

        @media (--until-tablet) {
            height: 30px;
            line-height: 30px;
            padding: 0 15px;
            margin-bottom: 10px;
            font-size: 14px;

            &::before {
                width: 30px;
                height: 30px;
                background-size: 12px auto;
            }

            .parental-modal & {
                display: block;
                width: 100%;
            }
        }
    }

    &-label {
        display: block;
        margin-bottom: 10px;

        @media (--until-tablet) {
            font-size: 14px;
        }

        @media (--mobile) {
            float: left;
            width: 38%;
            margin-bottom: 0;
            line-height: 40px;
            min-height: 40px;
            height: auto;

            &--radio {
                width: 100%;
            }

            .parental-modal & {
                width: 100%;
                float: none;
            }
        }
    }

    &-close {
        display: none;
        height: 40px;
        line-height: 40px;
        background-color: transparent;

        &:hover,
        &:focus {
            opacity: .6;

            &::after {
                opacity: .6;
            }
        }

        &:focus {
            outline: 0;
            opacity: .6;

            &::after {
                opacity: .6;
            }
        }

        .parental-modal & {
            width: 94px;
            position: absolute;
            right: 10px;
            top: 5px;
            padding-right: 40px;
            display: block;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 40px;
                height: 40px;
                text-indent: 0;
                background-image: url(../img/icon-close.png);
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 16px auto;
                transition: .5s opacity ease;
            }
        }

        &--thanks,
        .parental-modal &--thanks,
        &--off,
        .parental-modal &--off {
            position: relative;
            top: auto;
            right: auto;
            width: auto;
            display: inline-block;
            clear: both;
            background-color: $red;
            padding: 0 30px;

            &::after {
                display: none;
            }
        }

        &--link,
        .parental-modal &--link {
            position: relative;
            top: auto;
            right: auto;
            width: auto;
            display: inline-block;
            padding-right: 0;
            color: $red;
            font-size: 14px;
            clear: both;
            transition: .5s color ease;
            font-family: $sans;

            &::after {
                display: none;
            }

            &:hover,
            &:focus {
                background-color: transparent;
                color: $black;
            }
        }

        @media (--until-tablet) {

            .parental-modal & {
                width: 55px;
                height: 30px;
                line-height: 30px;
                top: 0;
                right: 10px;
                padding-right: 20px;
                font-size: 14px;
            }

            .parental-modal &::after {
                width: 20px;
                height: 30px;
                background-size: auto 12px;
                background-position: 100% 7px;
            }

            .parental-modal .parental-row &,
            .parental-modal .parental-warning--text & {
                width: auto;
                padding: 0 30px;
                right: auto;
                top: auto;
            }
        }
    }

    &-header {
        display: none;

        .parental-modal & {
            font-size: 20px;
            @mixin cf;
            text-align: left;
            display: block;
            width: 100%;
            padding: 0 20px;
            background: linear-gradient(to bottom, $red-dark 0%, $red 100%);
            color: #fff;
            height: 50px;
            line-height: 50px;
            position: absolute;
            top: 0;
            left: 0;

            @media (--until-tablet) {
                height: 30px;
                line-height: 30px;
                padding: 0 10px;
            }
        }
    }

    &-title {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 50px;
        padding-left: 40px;
        font-size: 20px;
        font-weight: $fwn;

        &--controls {
            background-image: url(../img/icon-unlocked.png);
            background-position: 0 50%;
            background-repeat: no-repeat;
            background-size: 20px auto;
        }

        &--guidance {
            position: relative;

            &::before {
                content: '';
                display: block;
                font-size: 17px;
                text-align: center;
                position: absolute;
                top: 12px;
                left: 0;
                width: 26px;
                height: 26px;
                line-height: 26px;
                background-color: #fff;
                background-image: url(../img/g-red.png);
                background-repeat: no-repeat;
                background-size: 17px auto;
                background-position: 50% 50%;
                color: $red;
                border-radius: 50%;
            }
        }

        @media (--until-tablet) {

            &--guidance::before {
                font-size: 12px;
                top: 7px;
                width: 14px;
                height: 14px;
                line-height: 16px;
            }
        }

        &.is_locked {
            background-image: url(../img/icon-locked.png);
        }

        @media (--until-tablet) {
            font-size: 14px;
            height: 30px;
            line-height: 30px;
            padding-left: 20px;
            background-size: auto 14px;
            background-position: 0 7px;
        }
    }

    &-block {
        display: none;

        &.is_active {
            display: block;
        }

        .parental-modal & {
            display: block;
            position: absolute;
            opacity: 0;
            backface-visibility: hidden;
            width: 600px;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-250%);
            transition: 1s transform ease, 1s z-index ease, 1s opacity ease;
            background-color: $grey-light;
            box-shadow: 0 0 20px 10px rgba(0,0,0,.3);
            color: #000;
            text-align: center;
            padding-top: 70px;
            z-index: -1;

            &.is_active {
                opacity: 1;
                transform: translate(-50%,-50%);
                z-index: 10;
            }
        }

        @media (--tablet) {

            .parental-modal & {
                width: 90%;
            }
        }

        @media (--until-tablet) {

            .parental-modal & {
                width: 95%;
                padding-top: 30px;
            }

            .parental-modal & p {
                font-size: 12px;
            }
        }
    }

    &-controls {
        display: none;
        @mixin cf;

        &.is_active {
            display: block;
        }

        .parental-modal & {
            padding-top: 70px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: stretch;

            &.is_active {
                opacity: 1;
                z-index: 10;
                display: flex;
            }

            @media (--until-tablet) {
                padding-top: 50px;
            }
        }
    }

    &-row {
        @mixin cf;
        position: relative;
        clear: both;
        padding: 0 0 20px;

        .parental-controls--setup &:nth-of-type(3),
        .parental-controls--setup &:nth-of-type(4) {
            width: 50%;
            float: left;
            clear: none;
        }

        .parental-controls--setup &:nth-last-of-type(1),
        .parental-controls--setup &:nth-last-of-type(2),
        .parental-controls--remove &:last-of-type {
            display: none;
        }

        small {
            display: block;
            margin-top: 10px;
            font-size: 14px;
            color: #4a4a4a;
            margin-bottom: 10px;
        }

        h2 {
            font-size: 20px;
            margin-top: 0;
            margin-bottom: 20px;
        }

        > p:first-child {
            display: none;

            .parental-modal & {
                display: block;
            }
        }

        @media (--until-tablet) {

            small {
                font-size: 12px;
                clear: both;
                margin-bottom: 5px;
                margin-top: 5px;
            }

            h2 {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }

        .parental-modal .parental-controls--setup &:nth-of-type(3),
        .parental-modal .parental-controls--setup &:nth-of-type(4) {
            width: auto;
            float: none;
            clear: none;
        }

        .parental-modal .parental-controls--setup &:nth-last-of-type(1),
        .parental-modal .parental-controls--setup &:nth-last-of-type(2),
        .parental-modal .parental-controls--remove &:last-of-type {
            display: block;
        }

        .parental-modal & {
            flex: 0 0 auto;
            padding: 0 40px 20px;
            text-align: center;

            > div {
                padding-bottom: 0;
                @mixin cf;
                width: 48%;
                float: left;
            }

            > div:last-child {
                margin-left: 4%;
            }

            &--centered {
                display: flex;
                justify-content: center;
            }

            &--centered > div {
                margin-left: 0;
                width: 100%;
                float: none;
            }
        }

        @media (--until-tablet) {

            .parental-modal & {
                padding: 0 10px 10px;
            }

            .parental-modal & > div {
                float: none;
                width: 100%;
                @mixin cf;
            }

            .parental-modal & > div:last-child {
                margin-left: 0;
            }

            .parental-controls--setup &:nth-of-type(3),
            .parental-controls--setup &:nth-of-type(4) {
                width: 100%;
                float: none;
                clear: both;
            }

            > div {
                padding-bottom: 0;
                @mixin cf;
                width: 100%;
                float: none;
            }
        }
    }

    &-warning {

        &--text {
            padding: 0 40px 20px;
            text-align: center;

            h2 {
                margin-top: 0;
                margin-bottom: 15px;
                font-size: 20px;
            }

            p {
                line-height: 20px;
                font-size: 14px;
            }

            a {
                color: $red;
                text-decoration: none;
                transition: .5s color ease;
            }

            a:hover,
            a:focus {
                color: #000;
            }
        }

        @media (--until-tablet) {
            padding-top: 50px;

            &--text {
                padding: 15px;
            }

            &--text h2 {
                font-size: 18px;
            }
        }
    }

    &-guidance-overlay {
        position: absolute;
        right: 180px;
        bottom: 0;
        height: 42px;
        background-color: #fff;
        padding: 0 20px 0 0;
        color: $black;
        line-height: 42px;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &::after {
            content: '';
            width: 1px;
            height: 22px;
            position: absolute;
            right: 0;
            top: 10px;
            display: block;
            background-color: $grey-light;
        }

        &::before {
            content: '';
            display: inline-block;
            font-size: 13px;
            text-align: center;
            position: relative;
            width: 26px;
            height: 26px;
            line-height: 26px;
            background-color: $red;
            background-image: url(../img/g-white.png);
            background-repeat: no-repeat;
            background-size: 13px auto;
            background-position: 50% 50%;
            color: #fff;
            border-radius: 50%;
            margin-right: 10px;
            font-family: $title;
            vertical-align: middle;
        }

        .production-video & {
            height: 36px;
            width: 36px;
            line-height: 36px;
            text-indent: 115%;
            white-space: nowrap;
            overflow: hidden;
            top: 0;
            bottom: auto;
            left: 0;
            right: auto;
            padding: 0;
            z-index: 10;

            &::after {
                display: none;
            }

            &::before {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 26px;
                height: 26px;
                line-height: 26px;
                text-indent: 0;
                margin: 0;
            }
        }

        @media (--until-tablet) {
            height: 42px;
            width: 52px;
            line-height: 42px;
            text-indent: 115%;
            white-space: nowrap;
            overflow: hidden;
            z-index: 10;
            padding: 0;

            &::before {
                position: absolute;
                left: 8px;
                top: 8px;
                width: 26px;
                height: 26px;
                line-height: 26px;
                text-indent: 0;
                margin: 0;
            }
        }
    }

    &-lock {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;
        background-color: #fff;
        color: #000;
        padding: 0 40px 0 10px;
        line-height: 42px;
        height: 42px;
        font-size: 13px;
        width: 180px;
        font-family: $title;
        margin: 0;
        border: 0;
        cursor: pointer;
        transition: .5s color ease, .5s background ease;

        &::before {
            content: '';
            display: block;
            width: 22px;
            height: 42px;
            background-repeat: no-repeat;
            background-size: 14px auto;
            background-position: 50% 50%;
            position: absolute;
            top: 0;
            right: 10px;
        }

        &:hover,
        &:focus {
            color: $red;
        }

        &.is_unlocked {

            &::before {
                background-image: url(../img/icon-unlocked-red.png);
            }

            .parental-lock--on {
                display: none;
            }

            .parental-lock--off {
                display: initial;
            }
        }

        &.is_locked {

            &::before {
                background-image: url(../img/icon-locked-red.png);
            }

            .parental-lock--on {
                display: initial;
            }

            .parental-lock--off {
                display: none;
            }
        }

        .nav-item & {
            height: 66px;
            line-height: 66px;
            width: auto;
            background-color: $grey-light;
            font-size: 16px;
            padding-left: 0;
            padding-right: 30px;
            position: relative;
            bottom: auto;
            right: auto;
            z-index: 0;
            display: block;
            text-align: right;

            &::before {
                width: 35px;
                height: 66px;
                right: auto;
                position: relative;
                display: inline-block;
                vertical-align: bottom;
            }
        }

        @media (--tablet) {

            .nav-item & {
                padding: 0 20px 0 0;
                font-size: 12px;
            }
        }

        @media (--until-tablet) {

            .nav-item & {
                height: 56px;
                line-height: 56px;
                text-align: left;
                padding: 0 30px 0 15px;
                border-top: 1px solid rgba(0,0,0,.1);
            }

            .nav-item &::before {
                height: 56px;
                width: 15px;
                position: absolute;
                top: 0;
                right: 15px;
            }
        }
    }
}

@keyframes pulse-border {

    0% {
        border-width: 1px;
        border-color: $grey;
    }

    50% {
        border-width: 2px;
        border-color: $red;
    }

    100% {
        border-width: 1px;
        border-color: $grey;
    }
}
